import React from 'react'
import Skills, { Carousel, MainCounter} from '../components/AboutmeComponents'
import {carouselImages} from "../data/aboutMeData.js"
import profilePic from "../images/AboutMePage/profilePic.jpeg"
import "../CSS/AboutMe.css"
import Timeline from '../components/TimeLine/Timeline'


//  =======================================================================================================
//  ######################################### ABOUT ME COMPONENT ##########################################
//  =======================================================================================================

const AboutMe = () => {

    return (
    <div className='aboutMeMainWrapper'>
		<Carousel carouselImages={carouselImages}/>
		<div className="profilePicWrapper center">
			<img className="profilePic center" src={profilePic} alt="" />
		</div>
		<div class="imgcircle white"></div>
		<div class="imgcircle red"></div>

		<p className="aboutMeDescription">
			<span className="aboutMeGreeting">"Hey there, <span className="aboutMeThank">Thank you</span> for visiting my page.</span> 
			<br/>	<br/>
			My journey in academia and industry is defined by a relentless pursuit of innovation in AI and language mastery. Weaving my skills 
			in Artificial Intelligence and my proficiency in programming, I strive to transcend traditional engineering boundaries. I am an 
			expert in leveraging AI to enrich and reinvent business strategies, I reshape industries and bend performance curves by integrating 
			the right hardware, software, and tools for AI development, deployment, and management, emphasizing scalable, responsible AI with 
			a strong focus on security, cost efficiency, and data governance
		</p>
		<h2 className="timelineHeading"> My Journey </h2>
		<Timeline/>
		<h2 id="skills" className="skillsMainHeading"> SKILLS</h2>
		<div className="skills">
			<Skills/>
		</div>
		<div className="counterMainWrapper">
			<MainCounter mainWrapperName="aboutmeCounter" numberName="aboutMeCounterNumber" headingName="aboutMeCounterHeading" wrapperName="aboutMeCounterWrapper"/>
		</div>

	</div>
  )
}

export default AboutMe
