import React  from 'react';

import Homepage from './pages/Homepage';
import Achievements, {SpecificAchievements} from './pages/Achievements';
import ExperienceTimeline from './pages/Experience';
import ContactMe from './pages/ContactMe';
import AboutMe from './pages/AboutMe';

import Navbar, {Footer} from './components/Base';
import '../src/CSS/Base.css'

import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import TimelineContentPage from './pages/subpages/TimelineContentPage';

import ReactGA from "react-ga4";

ReactGA.initialize("G-58J5G3LDL9");


window.baseUrl = "https://shubhambhatt.zeitconsultancy.com"





function App() {

    return (
    	<Router>
    	<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

		<div className="navbar-menu">
			<Navbar />
		</div>

		<div className="mainBody">
			<Routes>
				<Route path="/" exact element={<Homepage/>} />
				<Route path="Experience/">
					<Route path="" element={<ExperienceTimeline/>}/>
					<Route path=":id" element={<TimelineContentPage/>}/>
				</Route>
				<Route path="ContactMe/" element={<ContactMe/>} />
				<Route path="AboutMe/" element={<AboutMe/>} />
				<Route path="Achievements/" >
					<Route path="" element={<Achievements/>}/>
					<Route path=":choice" element={<SpecificAchievements/>}/>
				</Route>
			</Routes>
		</div>
		
		<div>
			<Footer/>
		</div>

		</Router>
	);
}

export default App;
