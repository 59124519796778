import React from 'react';
import "../CSS/ContactMe.css";
import HeaderIcon from "../images/ContactMePage/ContactHeader.jpg";
import ContactComponent from '../components/contactComponent';

const ContactMe = () => {

    return (
    <div>
		<div className="contactMeHeader">
			<div className="contactMeHeaderGrid">
				<div className="ContactMeHeaderColumn1">
					<img src={HeaderIcon} alt=''></img>
				</div>
				<div className="ContactMeHeaderColumn1">
					<h1> Wanna Know me in Person </h1>
				</div>
			</div>
		</div>
		<h2 className="contactMeSubHeader">
			A simple <b>HELLO</b> could lead to a million things!
		</h2>
		<ContactComponent/>
    </div>
  )
}

export default ContactMe
