import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import Calendar from "../../images/icons/Calendar.png";
import Location from "../../images/icons/Location.png";
import Position from "../../images/icons/Position.png";
import Skills from "../../images/icons/Skills.png";
import Certificate from "../../images/icons/Certificate.png";
import Marks from "../../images/icons/Marks.svg";

import SoftSkill from "../../images/TimelineContentPage/SoftSkill.png";
import TechnicalSkill from "../../images/TimelineContentPage/TechnicalSkill.png";
import { ReactComponent as TriangleIcon } from "../../images/TimelineContentPage/icon.svg";

import BoxComponent from '../../components/BoxComponent';
import {RiCloseCircleFill} from 'react-icons/ri';

function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
}


const TimelineContentPage = () => 
{
    let params = useParams()
    let workId = params.id
    let [work, setwork] = useState([])
    useEffect(() => { getwork() })

    let getwork = async () => 
    {
        let response = await fetch(window.baseUrl +`/api/experiences/${workId}`)
        let data = await response.json()
        //console.log("work: ", data)
        setwork(data)
    }

    const locationDescription = work.locationCity+', '+work.locationCountry   // LOCATION
    const duration = getMonthDifference(                                      // DURATION
        new Date(work.startDate), new Date(work.endDate)) + " Months"
    
    let attained = work.attainedSkills

    const [showskills, setshowskills] = useState('False');

    return (
        <div className="timelineContent" >

            <div className="headerContainer">
                <img className='headerPhoto' src={work.image} alt={work.employerOrInstitute}/>
                <div className='headerTitle'>
                    <h4 className="positionHeading"> {work.name} </h4>
                        at 
                    <h4 className="employerHeading"> {work.employerOrInstitute} </h4>
                </div>
            </div>
        

            <div className='subHeader'>
                <div className="subHeading">Description</div>
                <div className="description"> 
                    {work.description && work.description.trim() !== '<p>&nbsp;</p>' && (
                                    <p id="description">
                                        {work.description}
                                    </p>
                    )}
                </div>
            </div>

            <div className="subHeading details">Details</div>
            
            
            <div className="featuresContainer">
                {//console.log(work)
                }
                {(work.type === 'internship' || work.type === 'job')? 
                    <>
                        <BoxComponent image={<img className="symbol" src={work.symbol} alt={work.employerOrInstitute} />} heading="Employer" description={work.employerOrInstitute}/>
                        <BoxComponent image={<img className="symbol" src={Position} alt="Position" />} heading="Position" description={work.position} />
                    </>
                    : 
                    <BoxComponent image={<img className="symbol" src={Position} alt="Position" />} heading="Department" description={work.department} />
                }
                <BoxComponent image={<img className="symbol" src={Location} alt="Location" />} heading="Location" description={locationDescription} />
                {work.type === 'academic'? 
                    <BoxComponent image={<img className="symbol" src={Marks} alt="Projects" />} heading="CGPA" description={work.marks} />
                : null
                }
  
                <BoxComponent image={<img className="symbol" src={Calendar} alt="Duration" />} heading="Duration" description={duration}/>
                    
                <div className="detail-clk" onClick={() => setshowskills("True")}>
                    <BoxComponent image={<img className="symbol" src={Skills} alt="Skills" />} heading="Skills" description="To view the Skills click here" click="click"/>
                </div>

                {(work.type === 'internship' || work.type === 'academic')? 
                    <a className="detail-clk" href={work.file} target="_blank" rel="noopener noreferrer">
                        <BoxComponent image={<img className="symbol" src={Certificate} alt={(work.type === 'academic')? "Transcripts" : "Certificate"}/>} heading={(work.type === 'academic')? "Transcripts" : "Certificate"} description={"To view " + ((work.type === 'academic')? "Transcripts" : "Certificate") + " click here"} click="click"/>
                    </a>       
                    : null
                }
                
            </div>

            {showskills === "True" &&
                <>
                    <div className='triangleVector' >
                        <TriangleIcon/>
                    </div>
                    <div className="skillWrapper">
                        <div className="skillInnerWrapper">
                            <div className="closeIcon" onClick={() => setshowskills("False")}>
                                <RiCloseCircleFill/>
                            </div>
                            <div className="skillGrid">
                                {attained && attained.map((element, ind) => (
                                <BoxComponent image={<img className="skillIcon" src={(element.type.toLowerCase()==="hard skill")? TechnicalSkill: SoftSkill} alt={element.name}/>} heading={element.name} click="skillBox"/>    
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            }

            
            
            
            
            
        </div>
)}

export default TimelineContentPage
