import {aboutMeTimelineData as TimelineData} from "../../data/aboutMeData"

import {Carousel} from '../../components/AboutmeComponents'
import React, {useRef, useState} from 'react'

// #-------------------- Importing Timeline --------------------#
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {CgCloseO as CloseIcon} from "react-icons/cg";
import {IoImages as ShowMoreIcon} from "react-icons/io5";

import {IconContext} from "react-icons";





export default function Timeline(props) {
    const navRef = useRef();

    let[moreImages, setmoreImages] = useState([])    
    
    function showMore(params) {
        navRef.current.classList.toggle('showMore');
        //console.log(moreImages)
    }


    return (
    <>
        <VerticalTimeline>
            {
                TimelineData.map( (element, index) => {

                    return (
                    <>
                        <VerticalTimelineElement 
                        key = {index}
                        date = {element.image}
                        dateClassName = "date"
                        iconStyle = {{background : "#ffffff"}}
                        icon = {element.icon}
                        >
                            <div className="timelinegridcontainer">
                                <div className="timeline-column1">
                                    <h2 className="vertical-timeline-element-title">
                                        <div className="aboutMeTimelineHeading">
                                            {element.heading}
                                        </div>
                                        <IconContext.Provider value={{ size: 30}}>
                                            <div className={`aboutMeTimeline-btn`} onClick={function() { setmoreImages(element.moreImages); showMore();  }}>
                                                <ShowMoreIcon/>
                                                More Insight
                                            </div>
                                        </IconContext.Provider>
                                    </h2>
                                    
                                    <h4 className="vertical-timeline-element-subtitle">
                                        {element.subHeading}
                                    </h4>
                                </div>

                            </div>

                            <p id='description'>
                                {element.description}
                            </p>
                            

                        </VerticalTimelineElement>

                    </>)
                })

            

            }
        </VerticalTimeline>

            
                        
        <div ref={navRef} className="showMoreImages">
            {//console.log(moreImages)
            }
            <Carousel carouselImages={moreImages}/>

            <IconContext.Provider value={{ size: 40}}>
                <div className="carouselCloseIcon" onClick={showMore}>
                    <CloseIcon/>
                </div>
            </IconContext.Provider>
        </div>
    </>)
}