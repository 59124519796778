import React, {useState, useEffect, useRef} from 'react';
import {FaChevronCircleDown, FaChevronCircleUp} from 'react-icons/fa';
import {IconContext} from "react-icons";
import "../CSS/Achievement.css";
import Art from "../images/Achievement/Art.png"
import Other from "../images/Achievement/Other.png"
import Sport from "../images/Achievement/Sport.png"
import STEM from "../images/Achievement/STEM.png"
import {Link, useParams} from "react-router-dom";



//  =======================================================================================================
//  #################################### ACHIEVEMENTS MAIN COMPONENTS #####################################
//  =======================================================================================================

export default function Achievements () {

	const choice = [
		{
		image:Sport,  //Top Diamond
		title:"Sports"
		},
		{
		image:STEM,  //Right Diamond
		title:"STEM"
		},
		{
		image:Art,  //Left Diamond
		title:"Arts"
		},
		{
			image:Other,  //Bottom Diamond
			title:"Other"
		},
	];

    return (
		<>
			<h1 className="achievementHeader">
					Select Any One
			</h1>
			<div className="achievementsChoiceWrapper">
				{choice.map( (element, index) => (
					<Link to={element.title.toLowerCase()} >
						<div className="choice">
							<div className="achievementChoice" >	
								<img className="choiceImg" src={element.image}  alt={element.title}></img>
								<h2 className="choiceName">	
									{element.title}
								</h2>
							</div>
						</div>
					</Link>
				))}

			</div>
		</>
)
}


//  =======================================================================================================
//  #################################### ALL ACHIEVEMENTS COMPONENT #####################################
//  =======================================================================================================

export function SpecificAchievements () {
	let params = useParams()
    let choice = params.choice
	//console.log("choice:", choice)
	let[achievements, setachievements] = useState([])    
	useEffect(() => {getachievements()})

	let getachievements = async() => {
		let response = await fetch(window.baseUrl +`/api/achievement/?type__in=${choice}`)
		let data = await response.json()
		//console.log('awards:', data)
		setachievements(data)
	}

	const [expanded, setExpanded] = useState(false)
	const dataForDisplay = expanded ? achievements : achievements.slice(0, 6)


	const targetRef = useRef();
	const [width, setWidth] = useState();

	const getListSize = () => {
		if(targetRef.current){
		const newWidth = targetRef.current.clientWidth;
		setWidth(newWidth);}
		}
		useEffect(() => {
			getListSize();
		  }, [width]);


    return (
    <div>
		<h1 className="achievementHeader">
			CERTIFICATES / AWARDS
		</h1>

		<div className="achievementsMainWrapper">

			<div className="achievementDetailWrapper" style={{	"grid-template-columns":  dataForDisplay.length<4? (dataForDisplay.length<3? "repeat(1, 1fr)" : "repeat(3, 1fr)") : "repeat(4, 1fr)"}}>

				{dataForDisplay.map( (element, index) => (
					<div className="achievementWrapper">
						<div className="achievementBlock"
							style={{ "margin":  dataForDisplay.length<3? "100px auto 100px auto" : "auto" }}>
							<div className="achievementName">
								{element.name}
							</div>
							<div ref={targetRef} className="achievementDate">
								{element.date}
								{//console.log(width)
								}
							</div>
							<div className="achievementLocation">
								{element.locationCity}, {element.locationCountry}
							</div>
							<a className="detail-clk" href={element.file} target="_blank" rel="noopener noreferrer">
								<div className="achievementCertificate">
									Show Certificate
								</div>
							</a>
						</div>
					</div>
				))}
			</div>


			<div type="button" className="showMoreButton" onClick={() => setExpanded(!expanded)} 						
				style={				
						{
							"display":  achievements.length<6? "none" : "block"
						}
					}>
				<IconContext.Provider value={{ size: 50}}>
					{expanded ? <FaChevronCircleUp/> : <FaChevronCircleDown/>} 
				</IconContext.Provider>
			</div>

		</div>
    </div>
)}