import React, {useState, useEffect,useRef} from 'react'
import "../CSS/radio.css"


//  =======================================================================================================
//  ########################################### SKILLS COMPONENTS #########################################
//  =======================================================================================================

export default function SkillsRadio() {

    const [currentRadioValue, setCurrentRadioValue] = useState("")
    const handleRadioChange = async(e) => {
        //console.log(e.target.value)
        let response = await fetch(window.baseUrl +`/api/skills/?type=${e.target.value}`)
        let data = await response.json()
        setSkills(data)
        setCurrentRadioValue(e.target.value);
    };

    let[skills, setSkills] = useState([])       
    

    return (
        <div className="skill-radio">
            <div className="smile-rating-container">
                <div className="smile-rating-toggle-container">
                    <form className="submit-rating">
                        <input id="meh" value="soft skill"  name="satisfaction" type="radio" onChange={handleRadioChange}
                        checked={currentRadioValue === 'soft skill'} /> 
                        <input id="fun"  value="hard skill"  name="satisfaction" type="radio" onChange={handleRadioChange}
                        checked={currentRadioValue === 'hard skill'} /> 
                        <label for="meh" className="rating-label label-softskill">Soft Skills</label>
                        <div className="smile-rating-toggle"></div>
                        <div className="rating-eye rating-eye-left"></div>
                        <div className="rating-eye rating-eye-right"></div>
                        <div className="mouth rating-eye-bad-mouth"></div>
                        <div className="toggle-rating-pill"></div>
                        <label for="fun" className="rating-label label-hardskill">Technical Skills</label>
                    </form>
                </div>
            </div>

            <div className="skills">
            <h2 className="skillHeading">{currentRadioValue === "hard skill" ? "technical skills" : "soft skills"}</h2>
            <div className="skillsGrid">
                {skills.map((element, index) => {
                    return( 
                        <div className="skill" key={index}>
                        {element.name}
                    </div>
                    )
                })}                
            </div>
        </div>
        </div>
    )
}





//  =======================================================================================================
//  #################################### CAROUSEL COMPONENT #####################################
//  =======================================================================================================

export function Carousel(props) {
    
    // SLIDER
    const [current, setCurrent] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut = null;

    useEffect(() => {
    timeOut =
        // eslint-disable-next-line
        autoPlay &&
        setTimeout(() => {
            slideRight();
            }, 5000);
    });

    const slideRight = () => {
    setCurrent(current === props.carouselImages.length - 1 ? 0 : current + 1);
    };

    return (
        <div className="carousel"
            onMouseEnter={() => {
                setAutoPlay(false);
                clearTimeout(timeOut);
            }}
            onMouseLeave={() => {
                setAutoPlay(true);
            }}
        >
            <div className="carousel_wrapper">
                {props.carouselImages.map((element, index) => {
                    return( 
                        <div className={
                            index === current
                              ? "carousel_card carousel_card-active"
                              : "carousel_card"
                          }
                        >
                            <img className="card_image" src={element.image} alt="carousel" /> 
                            
                            <div className="card_overlay">
                                <h2 className="card_title">
                                    {element.title}
                                </h2>
                            </div>
                        </div>
                    )
                })}
                
                <div className="carousel_pagination">
                    {props.carouselImages.map((_, index) => {
                        return (
                            <div
                            key={index}
                            className={
                                index === current
                                ? "pagination_dot pagination_dot-active"
                                : "pagination_dot"
                            }
                            onClick={() => setCurrent(index)}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
)}



//  =======================================================================================================
//  #################################### COUNTER COMPONENT #####################################
//  =======================================================================================================

export function Counter(props) {

    const[state, setstate] = useState(null)
    const myref = useRef();

    // COUNTING
    const ref = useRef(0)
    const accumulator = props.end/30;

    // COUNTER FUNCTION
    const updateCounterState = () => {
        if(ref.current < props.end) {
            const result = Math.ceil(ref.current + accumulator)
            if(result > props.end) return setstate(props.end)
            setstate(result)
            ref.current = result
        }
        setTimeout(updateCounterState, 100)
    };


    // Scrolling Effect - Starts only after scrolling

    const [elementVisible, setElementVisible] = useState();
    //console.log("elementVisible", elementVisible)

    useEffect(() => {
        let CounterObserver = new IntersectionObserver(
            (entries, observer) => {
                let [entry] = entries;
                setElementVisible(entry.isIntersecting)
                if (!entry.isIntersecting) return;
                let isMounted = true;
                if (isMounted) {
                    updateCounterState();
                }
                return() => (isMounted = false);
            })
        CounterObserver.observe(myref.current);
    })

    return (
        <div ref={myref} className={"counterWrapper " + props.wrapperName}>
            <div  className={"counterNumber "+ props.numberName} data-target={props.countLimit}>
                {state} +
            </div>
            <h3 className={"counterHeading "+ props.headingName}> {props.heading}</h3>
        </div>
    )};



//  =======================================================================================================
//  ######################################## MAIN COUNTER COMPONENT #######################################
//  =======================================================================================================

export function MainCounter(props) {

    return (
        <div className= {"counterGrid " + props.mainWrapperName}>
            <Counter end="10" heading="Projects" numberName={props.numberName} headingName={props.headingName} wrapperName={props.wrapperName}/>
            <Counter end="30" heading="Awards" numberName={props.numberName} headingName={props.headingName} wrapperName={props.wrapperName}/>
            {/* <Counter end="2" heading="Papers Published" numberName={props.numberName} headingName={props.headingName} wrapperName={props.wrapperName}/> */}
            <Counter end="35" heading="Technical Skills" numberName={props.numberName} headingName={props.headingName} wrapperName={props.wrapperName}/>
        </div>
    )};



