import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import Slider from "react-slick";

// #-------------------- Importing Timeline --------------------#
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

// #-------------------- Importing Images --------------------#
import { ReactComponent as WorkIcon} from "../images/icons/Work.svg";
import { ReactComponent as AcademicIcon} from "../images/icons/Academics.svg";
import TechnicalPaper from "../images/ExperiencesPage/TechnicalPaper.jpg"
import Courses from "../images/ExperiencesPage/Course.jpg"
import CourseIcon from "../images/ExperiencesPage/CourseIcon.jpg"
import ResearchPaperIcon from "../images/ExperiencesPage/ResearchPaperIcon.jpg"

// #-------------------- Importing Icons --------------------#
import {FaChevronRight, FaChevronLeft} from 'react-icons/fa';
import {IconContext} from "react-icons";

// #-------------------- Importing Components --------------------#
import BoxComponent from '../components/BoxComponent';

// #-------------------- Importing CSS --------------------#
import "../CSS/Experience.css"  // CSS File




const Experience = () => 
{
  	let[experiences, setexperiences] = useState([])    
    useEffect(() => {getexperiences()}, [])
  	
	let[projects, setprojects] = useState([])    
    useEffect(() => {getprojects()}, [])

	let[papers, setpapers] = useState([])    
    useEffect(() => {getpapers()}, [])

	let[courses, setcourses] = useState([])    
    useEffect(() => {getcourses()}, [])

    // async makes a function return a Promise
    // await makes a function wait for a Promise
    let getexperiences = async() => {
        let response = await fetch(window.baseUrl +'/api/experiences/?type__in=job,internship,academic')
		//console.log(response)
        let data = await response.json()
        //console.log('experiences:', data)
        setexperiences(data)
    }
    
	let getprojects = async() => {
        let response = await fetch(window.baseUrl +'/api/experiences/?type=project')
        //console.log(response)
		let data = await response.json()
        // console.log('projects:', data)
        setprojects(data)
    }
    
	let getpapers = async() => {
        let response = await fetch(window.baseUrl +'/api/experiences/?type=paper')
        let data = await response.json()
	    // console.log('papers:', data)
        setpapers(data)
    }

	let getcourses = async() => {
        let response = await fetch(window.baseUrl +'/api/experiences/?type=course')
        let data = await response.json()
        // console.log('courses:', data)
        setcourses(data)
    }

	// -------------------------- CAROUSEL ------------------------------------

	const NextArrow = ({ onClick }) => {
		return (
			<div className="arrow next" onClick={onClick}>
				<IconContext.Provider value={{ size: 30}}>
					<FaChevronRight />
				</IconContext.Provider>
			</div>
		);
	};

	const PrevArrow = ({ onClick }) => {
		return (
		<div className="arrow prev" onClick={onClick}>
			<IconContext.Provider value={{ size: 30}}>
				<FaChevronLeft />
			</IconContext.Provider>
		</div>
		);
	};

	const [imageIndex, setImageIndex] = useState(0);

	const [selectedProjectType, setSelectedProjectType] = useState('Machine Learning');
	
	const selectedProjects = projects.filter(element => element.projectType === selectedProjectType);

	const settings = {
		lazyLoad: true,
		speed: 300,
		centerMode: true,
		slidesToShow: ((selectedProjects.length < 1)? 0: 1),
		centerPadding: 0,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		beforeChange: (current, next) => setImageIndex(next),
		};

	function stripHTML(html) {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = html;
		return tempElement.innerText || tempElement.textContent || "";
	}
	// ----------------------------------------------------------------
	

	

    return (
	<div className="experiencesMainContainer">
		<div className="timeline">
			<h1 id="Experiences" className="heading headingFont"> EXPERIENCES</h1>  
				
			<VerticalTimeline>
			{
				experiences.map( (element, index) => {
				
					let isJobIcon = element.type === "job"; 
					let isInternshipIcon = element.type === "internship"; 

					
					return (
						<VerticalTimelineElement 
						key = {index}
						date = {element.startDate + '  -  ' + (element.endDate ? element.endDate : 'Present')}
						dateClassName = "date"
						iconStyle = {{background : "#ffffff"}}
						icon = { isJobIcon? <WorkIcon/> : isInternshipIcon? <WorkIcon/> : <AcademicIcon/>}
						>
							<div className="timelinegridcontainer">
								<div className="timeline-column1">
									<h2 className="vertical-timeline-element-title">
										{element.name}
									</h2>
									
									<h4 className="vertical-timeline-element-subtitle">
										{element.employerOrInstitute + ' (' + element.locationCity + ', '+ element.locationCountry + ')'}
									</h4>
								</div>
								
								<div className="timeline-column2">
									{//console.log(element.symbol)
									}
									<img className="timelinesymbol" src={element.symbol} alt="" />
								</div>

							</div>
							<br/>
							{element.description && element.description.trim() !== '<p>&nbsp;</p>' && (
								<p id="description">
									{stripHTML(element.description)}
								</p>
							)}
							
							<Link to={`${element.id}`}>
								<div className={`button elementButton`}>
									{isJobIcon? "Know more !" : isInternshipIcon? "Know more !" : "See Academic Profile"}
								</div>
							</Link>
						</VerticalTimelineElement>
					)
				})
			}
			</VerticalTimeline>
		</div>
		
		<h1 id="Projects" className="projectHeading headingFont">
			PROJECTS
		</h1>

		<div className="projectSelectionHeader">
			<div className='projectSelectionHeading'>
				Select the project type:
			</div> 

			<div className='projectSelectionGrid'>
				<div  className='projectSelectionButton' onClick={() => setSelectedProjectType('machinelearning')}>
					Machine Learning
				</div>
				<div  className='projectSelectionButton' onClick={() => setSelectedProjectType('softwaredevelopment')}>
					Software Development
				</div>
				<div  className='projectSelectionButton' onClick={() => setSelectedProjectType('datascience')}>
					Data Science
				</div>
				<div className='projectSelectionButton' onClick={() => setSelectedProjectType('electricalengineering')}>
					Electrical Engineering
				</div>
			</div>
			

		</div>



		<Slider {...settings}>
		{selectedProjects.map((element, index) => (
				
				<div className={index===imageIndex? "slide activeSlide" : "slide"}>
					{/* <div className={index===imageIndex? "projectColumn2" : "noProjectName"}> */}

						{/* %%%%%%%%%%%%%%%%%%%%%%%%%% 	PROJECT TITLE  %%%%%%%%%%%%%%%%%%%%%%%%% */}
						<div className="projectTitle">
							{element.name} 
						</div>

						<div className="projectMainWrapperGrid">
							{/* ################### COLUMN 1 ################### */}
							{/* This column displays the image and skills */}
							<div className="projectColumn1">
								<img className="projectImg" src={element.image} alt={element.name} />
							
								<div className="projectSkills">
									{element.attainedSkills.map ((skill, index) =>(
										<div className='projectSkill'>
											<div className='skillText'>
												{skill.name}
											</div>
										</div>
									))}										
								</div>
							</div>

							{/* ################### COLUMN 2 ################### */}
							{/* This column displays the project details, link to github page and more infomation */}
							<div className="projectColumn2">
								<div className="projectDetails">
                                    <div className="projectDescription">
										{stripHTML(element.description)}
                                    </div>
                                </div>
							
															
								
								<div className={`knowMoreButton`}> 
									<a href={element.projectGit} target="_blank" rel="noopener noreferrer"
											style={{ textDecoration: 'none', color: 'inherit' }}>
										Know More 
									</a>
								</div>
								                       	
							
							</div>
						</div>
  					{/* </div> */}
  				</div>
			))}
		</Slider>

		
		{papers.length===0?
			""
			:
			<>
				<h1 className="paperHeading headingFont courseHeading">
							TECHNICAL PAPERS
				</h1>
			
				<div id="Papers" className="technicalPaperGrid">
					<div className="paperColumn1" style={{'display':(papers.length===0?'none' : "block")}}>
						<img src={TechnicalPaper}  alt=''></img>
					</div>
					
					<div className="paperColumn2">
							<div className="paperWrapperGrid" style={{"grid-template-columns": papers.length<3? "" : "1fr 1fr 1fr" , "display": papers.length<3? "" : "grid" }}>
								{papers.map((paper, index) => (
									<BoxComponent image={<img className="paperSymbol" src={ResearchPaperIcon} alt={paper.name} />} heading={paper.name} description={paper.description} />
								))}
							</div>
					</div>
				</div>
			</>
		}


		{courses.length===0?
			""
			:
			<>
				<h1 className="paperHeading courseHeading">
					COURSES
				</h1>

				<div id="Courses" className="courseGrid">
					<div className="paperColumn2">
							<div className="paperWrapperGrid" style={{"grid-template-columns": courses.length<3? "" : "1fr 1fr 1fr" , "display": courses.length<3? "" : "grid" }}>
								{courses.map((course, index) => (
									<a className="detail-clk" href={course.file} target="_blank" rel="noopener noreferrer">
										<BoxComponent image={<img className="paperSymbol" src={CourseIcon} alt={course.name} />} heading={course.name} 
															description=
															{course.description && course.description.trim() !== '<p>&nbsp;</p>' && (
																<p id="description">
																	{course.description}
																</p>
															)}
															click="click"/>
									</a>       
								))}
							</div>
					</div>

					<div className="paperColumn1">
						<img src={Courses}  alt=''></img>
					</div>
				</div>
			</>
		}
	</div>
	)
}

export default Experience
