import React, {useState, useEffect} from 'react'
import "../CSS/Homepage.css"
import MachineLearning from "../images/Homepage/MachineLearning.jpg"
import Projects from "../images/Homepage/Projects.jpg"
import BestExperience from "../images/Homepage/BestExperience.jpg"

import ExperienceLogos, {HomepageHeader, LeftBox, RightBox, Hobies, MoreAboutMe, Languages} from "../components/HomepageComponents.js"

import Design1 from "../images/Homepage/Design1.png";
import Design2 from "../images/Homepage/Design2.png";
import Resume from "../documents/CV.pdf"
import ReactGA from "react-ga";

const Homepage = () => 
{

	useEffect(() => {
		ReactGA.pageview("/homepage");
	}, []);




	const [offsetY, setOffsetY] = useState(0);
	const handleScroll = () =>setOffsetY(window.pageYOffset);

	useEffect(() =>{
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

    return (
    	<div>
        	<div className="homepage">
				<HomepageHeader/>
				<ExperienceLogos/>
				
				<h1 className="hpHeading"> A Little About Me </h1>
				
				{/* A Machine Learning Enthusiast */}
				<img className="design2" src={Design2} alt="Background" 
				style={{transform:`translateY(${offsetY * 0.1}px)`}} />
				
				<div className="machineLearningBox">
					<LeftBox heading="A Machine Learning Enthusiast" 
							image={MachineLearning} 
							description="I consider myself a problem-solving nerd who has a keen interest in coding and machine learning. I get such a rush from being able to combine my background in electrical engineering with the cutting-edge capabilities of machine learning to create practical, efficient, and effective robotic and automated solutions. To learn more about me as a person and my passion for coding, please continue reading below 👇." 
							linkto="/Experience#Projects" 
					/> 	
				</div>
				
				{/* Versatile range of Projects */} 
				<img className="design1" src={Design1} alt="Background" 
				style={{transform:`translateY(${offsetY * 0.05}px)`}} />
				
				<RightBox heading="Versatile range of Projects" 
						image={Projects} 
						description="I have completed various projects, from developing smart shoes to creating an A2C reinforcement learning agent. As an Electrical Engineer, my experience spans many projects and applications, including firefighter robots, underground cable fault distance locators, and more. My passion for machine learning and programming led me to create various projects, including a facemask detection system, multiple classification models utilizing data from Kaggle, a chatbot, DELIVBot, an autonomous vehicle project (using reinforcement learning), etc. As a self-proclaimed 'coding geek and full stack developer,' I've created several websites, including the one you're visiting right now 😁." 
						linkto="/Experience#Projects"  
				/> 
				
				{/* Experience from India's Best Institute */}
				
				<LeftBox heading="Experience from India's Best Institute" 
						image={BestExperience}
						description="My internship was in the Computer Science and Engineering (CSE) Department of IIT Bombay, one of India's top universities.  Data analytics was my primary focus, and I was responsible for pulling information from the server and performing statistical analysis and interpretation to identify patterns and trends. My study with Prof. Ganesh Ramakrishnan has focused on developing a semi-supervised Machine Learning model for use with the Covid-19 dataset." 
						linkto="/Experience#Experiences"  
				/>	
				
				<h1 className="greyHeading">HOBBIES</h1>				
				<Hobies/>

				<p className="center hpQuote"> <i>“Real growth occurs when one goes <b>BEYOND</b> one's limits. Realizing that is also part of training” </i> </p>
				<h1 className="center hpHeading languageHeading"> Languages </h1>
				<Languages/>	{/* MultiLinguistic */}

				<h1 className="center hpHeading languageHeading knowMoreHeading">Wanna Know More About Me? Explore the options below !</h1>
				<MoreAboutMe/>	{/* Programming */}

				<div className='resumeDocument'>
					<div className='resumeGrid'>
						<h3 className='resumeText'>Want a PDF Version of the Resume! Download it from here! <br/></h3>  
						
						<div className="resumeButton"> 
							<a className="detail-clk" href={Resume} download="Shubham Bhatt - Resume" target="_blank" rel="noopener noreferrer"> 
								DOWNLOAD RESUME 
							</a> 
						</div>
					</div>
					
				</div>
        	</div>
      	</div>
    )
}

export default Homepage

