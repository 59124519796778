import React, {useState, useEffect} from 'react'
import { NavHashLink as Link } from 'react-router-hash-link';

import Hobbies from "../images/Homepage/Hobbies.jpg"
import Hobbies2 from "../images/Homepage/Hobbies2.jpg"
import German from "../images/Homepage/German.svg"
import India from "../images/Homepage/India.svg"
import English from "../images/Homepage/English.png"
import DesignDiamond from "../images/Homepage/DesignDiamond.png"
import HeaderDesign from "../images/Homepage/HeaderDesign.png"

import BoxComponent from '../components/BoxComponent';
import {MainCounter} from "../components/AboutmeComponents"
import HomepagePhoto from "../images/Homepage/homepagephoto.png"





//  =======================================================================================================
//  ################################ EXPERIENCE LOGOS HOMEPAGE COMPONENT ##################################
//  =======================================================================================================

export default function ExperienceLogos () {

    let[experienceLogos, setexperienceLogos] = useState([])    
    useEffect(() => {getexperienceLogos()}, [])

    //await makes a function wait for a Promise
    let getexperienceLogos = async() => {
        let response = await fetch(window.baseUrl +'/api/experiences/')
        let data = await response.json()
        //console.log('experiences Images:', data)
        setexperienceLogos(data)
    }

    // Getting all unique Symbols
    var expImages = [];
    var expNames = [];

    experienceLogos.forEach((element, i) => {
        if(expNames.includes(element.employerOrInstitute) || expNames.includes(element.universityName) ){
            //pass
        }
        else{
            if(element.symbol!=null){
                expImages.push(element.symbol)
                expNames.push(element.employerOrInstitute)    
            }
        }
    })

    //console.log("Exp images: ", expImages)
    //console.log("exp names: ", expNames)
    
    return(
        <div className="experienceSymbols center">
            {expImages.map( (element, index) => (
                <div className="expSym center">
                    <img className="expImage center" src={element} alt={element} loading="lazy"/>
                </div>
            ))}
        </div>
    )
}




//  =======================================================================================================
//  #################################### HOMEPAGE HEADER COMPONENT #####################################
//  =======================================================================================================

export function HomepageHeader () {
    return(
    <div className="hpHeader">
        <div className="hpHeaderGrid">

            <div className="hpHeaderColumn1">
                    <div className="headerName1">
                    Welcome to 
                    </div>
                    <div className="headerName2">
                        <span className='yellow-txt'>Shubham Bhatt's</span> Universe
                    </div>
                    <div className="headerDescription homepageSmallFont">
                        Unlock the stories of my projects, awards, and technical prowess. Dive in and let's make history together!
                    </div>
                    <div className="homepageCounterWrapper">
                        <MainCounter mainWrapperName="homepageCounter"/>
                    </div>
            </div>
            <img src={HeaderDesign} alt="Header Design" className="hpHeaderPhoto"  loading="lazy"/>
            <div className="hpHeaderColumn2">
                <img src={HomepagePhoto} alt="Shubham Bhatt" className="shubhamphoto"  loading="lazy"/>
            </div>
        </div>
    </div>
)}

export function LeftBox (props) {
    return(
    <div className="leftBoxContainer">
        <Link className="hpImageWrapper" to={props.linkto}>
            <img className="fitImage boximg" src={props.image} alt=""  loading="lazy"/>   {/* Outer Grid - Column 1 */}
        </Link>
        <div className='boxText'>
            <div className="hpboxHeading">
                {props.heading}
            </div>
            <div className="hpdescription">
                {props.description}
            </div>
        </div>
    </div>
)}



export function RightBox (props) {
    return(
    <div className="rightBoxContainer">
        <div className='boxText'>
            <div className="hpboxHeading">
                {props.heading}
            </div>
            <div className="hpdescription">
                {props.description}
            </div>
        </div>
        <Link className="hpImageWrapper" to={props.linkto}>
            <img className="fitImage smallerImg boximg" src={props.image} alt=""  loading="lazy"/>   {/* Outer Grid - Column 1 */}
        </Link>
    </div>
)}



export function Recommendations () {
    return(
    <div className="recommendationsContainer">
        
    </div>
)}



//  =======================================================================================================
//  #################################### HOBBIES COMPONENT #####################################
//  =======================================================================================================

export function Hobies () {
    
    let[extraCurricular, setextraCurricular] = useState([])    
    useEffect(() => {getextraCurricular()}, [])

    //await makes a function wait for a Promise
    let getextraCurricular = async() => {
        let response = await fetch(window.baseUrl +'/api/hobbies/')
        let data = await response.json()
        //console.log('Extra Curricular:', data)
        setextraCurricular(data)
    }

    return(
    <div className="hobiesContainer">     {/* Outer Grid */}
        <img className="hobbiesImage" src={Hobbies} alt="Hobbies"  loading="lazy"/>   {/* Outer Grid - Column 1 */}

        <div className="hobiesInnerGrid">    {/* Outer Grid - Column 2 */}
            <div className="hobbies">   {/* Inner Grid - Column 1 */}
                <div className="hobiesHeadingGrid">
                    {/* Heading Grid - Column 1 */} 
                        <h2 className="hobbiesQuote">
                            <i>
                                " You never lose a dream, it just incubates as a <span className="bold"> hobby </span> "
                            </i><br/><br/>
                            <i className="hobbyQuoteAuthor">
                                -Larry Page
                            </i>
                        </h2>

                    {/* Heading Grid - Column 2 */}
                    <img className="fitImage" src={Hobbies2} alt="Hobbies"  loading="lazy"/>
                </div>
            </div>

            <div className="hobiesGrid">    {/* Inner Grid - Column 2 */}
                {extraCurricular.map( (element, index) => (
                    <BoxComponent image={<img className="hobby" src={element.image} alt={element.name}  loading="lazy"/>} heading={element.name} description={element.description} click="hobiesBox"/>
                ))}
            </div>
        </div>
    </div>
)}



//  =======================================================================================================
//  #################################### LANGUAGES COMPONENT #####################################
//  =======================================================================================================

export function Languages () {
    return(
    <div className="languagesContainer">
            <BoxComponent image={<div className="image-cropper"><img className="languageImg" src={India} alt="Hindi"  loading="lazy"/></div>} heading="Hindi" description={<span className="langDesc">Native Proficiency</span>} click="languageBox"/>        
            <BoxComponent image={<div className="image-cropper"><img className="languageImg" src={India} alt="Garhwali"  loading="lazy"/></div>} heading="Garhwali" description={<span className="langDesc">Native Proficiency</span>} click="languageBox"/>
            <BoxComponent image={<div className="image-cropper"><img className="languageImg" src={India} alt="Marathi"  loading="lazy"/></div>} heading="Marathi" description={<span className="langDesc">Native Proficiency</span>} click="languageBox"/>
            <BoxComponent image={<div className="image-cropper"><img className="languageImg" src={English} alt="English"  loading="lazy"/></div>} heading="English" description={<span className="langDesc">Bilingual Proficiency</span>} click="languageBox"/>
            <BoxComponent image={<div className="image-cropper"><img className="languageImg" src={German} alt="German" loading="lazy" /></div>} heading="German" description={<span className="langDesc">Working Proficiency</span>} click="languageBox"/>
    </div>
)}


//  =======================================================================================================
//  #################################### SOFT SKILLS COMPONENT #####################################
//  =======================================================================================================
export function Softskills () {
    return(
    <div className="softSkillsContainer">
        Soft Skills
    </div>
)}


//  =======================================================================================================
//  #################################### MORE ABOUT ME COMPONENT #####################################
//  =======================================================================================================

export function MoreAboutMe () {
    return(
    <>
        <div className="moreAboutMeContainer">

            <div className='exploreInnerGrid'>
                <Link to="Experience#Projects" className='more'>
                    <h3 className="moreText">
                        Projects
                    </h3>
                </Link>
                <Link to='AboutMe#skills' className='more'>
                    <h3 className="moreText">
                        Skills
                    </h3> 
                </Link>
                <Link to="Experience#Courses" className='more'>
                    <h3 className="moreText">
                        Courses
                    </h3>
                </Link>
                <Link to='Experience#Experiences' className='more'>
                    <h3 className="moreText">
                        Work Experience
                    </h3>
                </Link>
            </div>

            <div className="sideDesign1">
                <img className="designDiamondImg1" src={DesignDiamond} alt="Experiences"  loading="lazy"/>
            </div>
            
            <div className="sideDesign2">
                <img className="designDiamondImg2" src={DesignDiamond} alt="Experiences"  loading="lazy"/>    
            </div>              
            
        </div> 
    </>
)}