import React from 'react';

import AddressImage from "../images/ContactMePage/addressImage.jpg"
import CallImage from "../images/ContactMePage/callImage.jpg"
import EmailImage from "../images/ContactMePage/emailImage.jpg"

import {ImLocation2} from 'react-icons/im';
import {IoMdCall} from 'react-icons/io';
import {MdEmail} from 'react-icons/md';
import {IconContext} from "react-icons";

import "../CSS/ContactMe.css";
import BoxComponent from './BoxComponent';



const ContactComponent = () => {

    return (
    <div>
        <div className="ContactInfoGrid">
			{/* ROW 1 */} 
			<div className="ContactInfoImageWrapper">
				<img src={CallImage} alt="Call Now" className="ContactInfoImage" />
			</div>

			<BoxComponent image={
			<IconContext.Provider value={{ size: 30}}>
				<ImLocation2/>
			</IconContext.Provider>
			} heading="Let's Meet" description="Blacksburg , United States" />

			<div className="ContactInfoImageWrapper">
				<img src={EmailImage} alt="Call Now" className="ContactInfoImage" />
			</div>
	
			{/* ROW 2 */}
			<BoxComponent image={
			<IconContext.Provider value={{ size: 30}}>
				<IoMdCall/>
			</IconContext.Provider>
			} heading="Call Now" description="" />
			
			<div className="ContactInfoImageWrapper">
				<img src={AddressImage} alt="Call Now" className="ContactInfoImage" />
			</div>
			
			<a className="contactMeEmail" href="mailto:shubhamsbhatt01@gmail.com"  target="_blank" rel="noopener noreferrer">
				<BoxComponent image={
				<IconContext.Provider value={{ size: 30}}>
					<MdEmail/>
				</IconContext.Provider>
				} heading="Email Now" description="shubhamsbhatt01@gmail.com" />
			</a>
        </div>
        
    </div>
  )
}

export default ContactComponent
