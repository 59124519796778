import React from 'react'
import "../CSS/Experience.css"

const BoxComponent = (props) => {
  return (
    <div className= {"boxContainer " + (props.click)}>
        <div className="boxInnerContainer">
            {props.image}
            <div className="boxHeading ver-cen">
                <p>{props.heading}</p>
            </div>
            <div className="boxDescription ver-cen">
                <p>{props.description}</p>
            </div>
        </div>
    </div>
  )
}

export default BoxComponent
