import React, {useState, useEffect, useRef} from 'react'
import { NavHashLink as Link } from 'react-router-hash-link';
import Linkdin from "../images/icons/Linkdin.svg";
import Github from "../images/icons/Github.svg";
import Instagram from "../images/icons/Instagram.svg";
import Email from "../images/icons/Email.svg";
import LogoLight from "../images/LogoLight.png";
import LogoDark from "../images/LogoDark.png";
import {CgMenuGridR as MenuIcon} from "react-icons/cg";
import {GrClose as CloseIcon} from "react-icons/gr";
import {IconContext} from "react-icons";
import Resume from "../documents/CV.pdf"

// Here we create a Navbar
export default function Navbar() {

    const[scrollTop, setScrollTop] = useState(0);
    
    const onScroll = () =>{
        const winScroll = document.documentElement.scrollTop;
        const height =
            document.documentElement.scrollHeight - 
            document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        setScrollTop(scrolled);
    }

    useEffect(() => {
        window.addEventListener('scroll',onScroll);
        return () => window.removeEventListener('scroll',onScroll);
    }, []);


    const navRef = useRef();

    const showNav = () => {
        navRef.current.classList.toggle('responsive_nav');
    }


    return (
        <div ref={navRef} className="nav">

            <div className="mobileNavbar">
                <IconContext.Provider value={{ size: 30}}>
                <div className="menuIcon" onClick={showNav}>
                    <MenuIcon/>
                </div>
                <div className="navCloseIcon" onClick={showNav}>
                    <CloseIcon/>
                </div>
				</IconContext.Provider>
                
            </div> 
            <div>
                <div className="navbar-container-grid">

                    <div className="navbar navbarLeftContainer">
                        <Link to="/"> <img className='logo' src={LogoDark} alt="Logo" /> </Link>
                    </div>

                    <div className="navbarRightContainer desktopNav ">
                        <Link className='navbar-link' to="/" onClick={showNav}>Home</Link>
                        <Link className='navbar-link' to="/Experience" onClick={showNav}>Experience</Link>
                        <Link className='navbar-link' to="/Achievements" onClick={showNav}>Achievements</Link>
                        <Link className='navbar-link' to="/AboutMe" onClick={showNav}>About Me</Link>
                    </div>

                    <div className="contactMe hide">
                        <a className="yellow-resume-btn" href={Resume} download="Shubham Bhatt - Resume" target="_blank" rel="noopener noreferrer"> 
							Resume 
						</a> 
                        <Link className='navbar-link contactMeLink' to="/ContactMe">Contact Me</Link>
                    </div>

                </div>   


                <div className="progressMainWrapper">
                    <div className="progressMainStyle" style={{width:`${scrollTop}%`}}/>
                </div>

            </div>
        </div>
    )
}


export function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="footerVerticalGrid">
                <div className='footerLogo'>
                    <Link to="/"> <img className='logo' src={LogoLight} alt="Logo" /> </Link>
                </div>
                <div className="footerHorizonalGrid1">
                    <div className="list-head"><Link to="AboutMe">About Me</Link></div>
                    <div className="list-head"><Link to="ContactMe">Contact Me</Link></div>
                </div>
                <div className="divider"></div>
                <div className="footerHorizonalGrid2">
                    <div className="footer-link"><Link to="Experience#Courses">Courses</Link></div>
                    <div className="footer-link"><Link to="Achievements">Awards</Link></div>
                    <div className="footer-link"><Link to="Experience#Experiences">Academics</Link></div>
                    <div className="footer-link"><Link to="Experience#Experiences">Job</Link></div>
                    <div className="footer-link"><Link to="Experience#Projects">Projects</Link></div>
                    <div className="footer-link"><Link to="Experience#Papers">Technical Papers</Link></div>
                </div> 
                <div className="footerHorizontalGrid3">
                    <a href="mailto:shubhambhattimportant@gmail.com"  target="_blank" rel="noopener noreferrer">
                        <img className="social-icon-svg" src={Email} alt="Email"/>
                    </a>
                    <a href ="https://www.instagram.com/the_shubham_bhatt/" target="_blank" rel="noopener noreferrer">
                        <img className="social-icon-svg" src={Instagram} alt="Instagram"/>
                    </a>
                    <a href="https://github.com/Shubham-S-Bhatt"  target="_blank" rel="noopener noreferrer">
                        <img className="social-icon-svg" src={Github} alt="Github"/>
                    </a>
                    <a href="https://www.linkedin.com/in/shubham-s-bhatt/"  target="_blank" rel="noopener noreferrer">
                        <img className="social-icon-svg" src={Linkdin} alt="Linkdin"/>
                    </a>
                </div>
                <div className="copyright" >
                    <p>Copyright &copy; {currentYear} Shubham Bhatt</p>
                </div>
            </div>
        </footer>
)}


