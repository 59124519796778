import React from 'react'
import carouselImg1 from "../images/AboutMePage/myImages/photo1.jpg"
import carouselImg2 from "../images/AboutMePage/myImages/photo2.jpg"
import carouselImg3 from "../images/AboutMePage/myImages/photo3.jpg"
import carouselImg4 from "../images/AboutMePage/myImages/photo4.jpg"
import carouselImg5 from "../images/AboutMePage/myImages/photo5.jpg"
import carouselImg6 from "../images/AboutMePage/myImages/photo6.jpg"

import AboutMeCarouselImage1 from "../images/LogoDark.png"
import AboutMeCarouselImage2 from "../images/LogoLight.png"

import Art from '../images/AboutMePage/Art.jpg'
import Multitalented from '../images/AboutMePage/Multitalented.jpg'
import Subjects from '../images/AboutMePage/Subjects.jpg'
import TechProject from '../images/AboutMePage/TechProject.jpg'
import Coding from '../images/AboutMePage/Coding.jpg'
import Job from '../images/AboutMePage/Job.jpg'
import AI from '../images/AboutMePage/AI.jpg'
import Language from '../images/AboutMePage/Language.jpg'
import Topper from '../images/AboutMePage/Topper.jpg'

// LANGUAGE IMAGES
import A1 from '../images/AboutMePage/JourneyTimeline/Languages/A1.jpg'
import A2 from '../images/AboutMePage/JourneyTimeline/Languages/A2.jpg'
import B1 from '../images/AboutMePage/JourneyTimeline/Languages/B1.jpg'
import B21 from '../images/AboutMePage/JourneyTimeline/Languages/B2.1.jpg'
import B22 from '../images/AboutMePage/JourneyTimeline/Languages/B2.2.jpg'





export const carouselImages = [
    {
        image: carouselImg1,
        title: "Virginia Tech"
    },
    {
        image: carouselImg2,
        title: "First Breaking Battle in JMU - Harrisonburg, Virginia "
    },
    {
        image: carouselImg3,
        title: "Lane Stadium - Virginia Tech"
    },
    {
        image: carouselImg4,
        title: "Electrolux Headquarters - Stockholm, Sweden"
    },
    {
        image: carouselImg5,
        title: "Stockholm, Sweden"
    },
    {
        image: carouselImg6,
        title: "Dragon's Tooth Hiking Trail, Virginia"
    },
];


export const aboutMeTimelineData=[
    {
        heading:"The Art Enthusiast",
        subHeading:"Painting, Craft, Singing, Dancing",
        date:"",
        description:`From a young age, I have found profound joy and fulfillment in the arts, embracing activities like painting, crafting, 
                singing, and dancing. Drawing and sketching have always been more than hobbies; they are ways I interpret and interact with 
                the world around me. Additionally, my passion for performance arts is evident in my love for singing and especially in my 
                enthusiasm for dance. I am particularly captivated by break dancing and b-boying, forms of expression that challenge me to 
                physically articulate my artistic vision while having fun and often breaking the rules. These disciplines reinforce my 
                belief that creating art is about exploring new possibilities, making bold choices, and, above all, enjoying the process.🎨.`,
        image: <img className="timelineImage" src={Art} alt="" />,
        icon: "",
        moreImages: [
            {image: AboutMeCarouselImage1,
            title:""},
            {image: AboutMeCarouselImage1,
            title:""},
            {image: AboutMeCarouselImage1, 
            title:""},
        ],
    },

    {
        heading:"Favourite Subjects",
        subHeading:"Math, Science and Arts",
        date:"",
        description: `I've have always had a profound interest in both the sciences and the arts, with mathematics holding a 
                        special place among my favorite subjects. The clear, logical structure of mathematics—where problems typically 
                        have definite, true or false answers—has always resonated with me. Science, likewise, has been a constant 
                        inspiration in my life. As a child, I was always observing the world, identifying patterns, and seeking explanations 
                        for how things function. This scientific curiosity complements my creative inclinations, allowing me to explore a wide 
                        array of interests without limiting my imagination. Together, these disciplines shape my holistic approach to learning 
                        and understanding the world around me.`,
        image: <img className="timelineImage" src={Subjects} alt="" />,
        icon: "",
        moreImages: [
            {image: AboutMeCarouselImage2,
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
        ]
    },

    {
        heading:"The Perfectionist",
        subHeading:"Trying to be good at everything",
        date:"",
        description:`From a young age, I've harbored a deep curiosity about the world around me, driven by a desire to understand how 
                    things work. My interests span a diverse range of fields—from technology and art to cooking and carpentry. I believe 
                    that while mastery in every area may be unattainable, understanding the fundamental principles behind various disciplines 
                    is essential. This broad knowledge base fuels my personal and professional growth. Through continuous learning and 
                    dedicated practice, I strive to enhance my skills and knowledge in every endeavor.`,
        image: <img className="timelineImage" src={Multitalented} alt="" />,
        icon:"",
        moreImages: [
            {image: AboutMeCarouselImage2,
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
        ]
    },

    {
        heading:"First Technical Project",
        subHeading:"Smart Shoes",
        date:"",
        description:`From an early age, I was driven by a desire to innovate and create solutions that could benefit others. Even as a child, 
                    I had a keen eye for detail and often found myself observing the world around me with curiosity. One observation that particularly 
                    struck me was the kinetic energy generated by people simply walking—a resource that was going completely untapped. At the age of eleven, 
                    inspired by this realization, I embarked on my first significant technical project: the creation of Smart Shoes. The concept was 
                    simple yet impactful: harness the kinetic energy produced by walking to generate electricity. These shoes were designed to convert 
                    this energy into electrical power, enough to charge a battery. This stored energy could then be used to power a mobile phone or 
                    other small devices. This project not only marked the beginning of my journey in technical innovation but also reflected my 
                    deep-rooted passion for making a meaningful impact through creative thinking and engineering.`,
        image: <img className="timelineImage" src={TechProject} alt="" />,
        icon: "",
        moreImages: [
            {image: AboutMeCarouselImage2,
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
        ]
    },

    {
        heading:"First Introduction to coding",
        subHeading:"C++",
        date:"1 july",
        description:`After successfully developing the initial version of my Smart Shoes, I ventured further into enhancing their functionality 
                    with Smart Shoes 2.0. This version was specifically designed to assist the visually impaired, incorporating advanced sensors 
                    such as ultrasonic and PIR to detect obstacles within a range of five meters. This innovation marked my first foray into the 
                    realm of programming and embedded systems. To integrate these sensors effectively, I delved into programming an Arduino using 
                    embedded C. This was my initial exposure to coding, and it immediately captivated me. Learning to code in C++ opened up a new 
                    world of problem-solving and logical thinking. It was exhilarating to see how coding allowed for the translation of my ideas 
                    into tangible, functional technology. This experience was a significant catalyst in my journey as a coder. It encouraged me 
                    to expand my programming skills, leading me to gain proficiency in a variety of languages, including Python, C, embedded C, 
                    JavaScript, HTML, CSS, and R.`,
        image: <img className="timelineImage" src={Coding} alt="" />,
        icon: "",
        moreImages: [
            {image: AboutMeCarouselImage2,
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
        ]
    },

    {
        heading:"First Professional Experience",
        subHeading:"AI Engineer Intern at IIT, Bombay",
        date:"",
        description:(<p><i>“The future depends on what you do today.” —Mahatma Gandhi </i> <br/> 
         This philosophy guided me as I embarked on my first professional experience, which was instrumental in shaping my career path. 
         I had the privilege of working as a AI Engineer Intern at the Indian Institute of Technology, Bombay, India's premier technical \
         institute. During my tenure, I was deeply involved in supervised, semi-supervised and unsupervised machine learning projects, 
         focusing on data analytics and model development. I engaged in projects that required effective time management and leadership, 
         pushing me to become more organized and proactive. Perhaps most crucially, this experience enhanced my ability to think creatively 
         under pressure, finding innovative solutions to complex problems. Working at such a renowned institution early in my career was a 
         profound learning experience, providing not just practical technical knowledge but also a solid foundation in the soft skills 
         essential for professional success.</p>),
        image: <img className="timelineImage" src={Job} alt="" />,
        icon: "",
        moreImages: [
            {image: AboutMeCarouselImage2,
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
        ]
    },

    {
        heading:"Conquering AI",
        subHeading:"Machine Learning and Artificial Intelligence",
        date:"",
        description:`Like many others, my fascination with machine learning began in a rather everyday context—using platforms like YouTube, Instagram, 
                    and Netflix. I was captivated by the precision with which these platforms recommended content that felt tailor-made for my interests. 
                    This sparked a desire to understand the underlying mechanisms that made such personalized experiences possible.Driven by this curiosity, 
                    I delved deep into the realms of machine learning, exploring various approaches such as Supervised, Unsupervised, and Reinforcement 
                    Learning. Each project I undertook expanded my knowledge and sharpened my technical skills, allowing me to apply theoretical concepts 
                    in practical scenarios. Eager to bend the AI curve, I enrolled in a specialized course on machine learning and IoT during college. 
                    This educational pursuit wasn't just about learning how AI works; it was about understanding how to make devices smarter and more 
                    responsive through the seamless integration of AI and IoT. This journey through the world of artificial intelligence has not only 
                    broadened my technical expertise but also continually fuels my passion for innovation in the digital age.📱.`,
        image: <img className="timelineImage" src={AI} alt="" />,
        icon: "",
        moreImages: [
            {image: AboutMeCarouselImage2,
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
            {image: AboutMeCarouselImage2, 
            title: ""},
        ]
    },
    

    {
        heading:"First Foreign Language",
        subHeading:"German / Deutsch",
        date:"1 july",
        description:`The prospect of learning a foreign language has always intrigued me, driven by the understanding that language acquisition 
                    can significantly enhance cognitive abilities, including memory, creativity, and problem-solving skills. My journey into 
                    foreign languages began with German. I embarked on this linguistic adventure through the Goethe Institute, where I have 
                    successfully completed the B2 Intermediate Level of their German language program. This achievement in German has not only 
                    improved my linguistic skills but has also deepened my appreciation for the nuances of cultural communication. The experience 
                    has encouraged me to continue expanding my linguistic repertoire. Next on my agenda is tackling the Spanish language, an 
                    endeavor I approach with enthusiasm and a keen desire to embrace new challenges. Learning these languages opens up a world 
                    of opportunities, from understanding different cultures to exploring new academic and professional horizons. 😁.`,
        image: <img className="timelineImage" src={Language} alt="" />,
        icon: "",
        moreImages: [
            {image: B22,
            title: "Teilnahmebestätigung - B2.2"},
            {image: B21, 
            title: "Teilnahmebestätigung - B2.1"},
            {image: B1, 
            title: "Teilnahmebestätigung - B1"},
            {image: A2, 
            title: "Teilnahmebestätigung - A2"},
            {image: A1, 
            title: "Teilnahmebestätigung - A1"},
        ]
    },

    {
        heading:"first rank holder in University",
        subHeading:"Mumbai University",
        date:"",
        description:`Despite being actively involved in extracurricular activities, I have always prioritized my academics at Mumbai University. 
                    My commitment to understanding core concepts and developing strong analytical skills has helped me maintain an excellent 
                    academic record. This focus and dedication led me to achieve the distinction of being the first rank holder at the university, 
                    a testament to my academic commitment and a strong foundation for my future endeavors.`,
        image: <img className="timelineImage" src={Topper} alt="" />,
        icon: "",
        moreImages: [
            {image: B22,
            title: "Teilnahmebestätigung - B2.2"},
            {image: B21, 
            title: "Teilnahmebestätigung - B2.1"},
            {image: B1, 
            title: "Teilnahmebestätigung - B1"},
            {image: A2, 
            title: "Teilnahmebestätigung - A2"},
            {image: A1, 
            title: "Teilnahmebestätigung - A1"},
        ]
    },

]